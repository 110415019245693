import './styles.scss'

function FullPage ({ children, className = '' }) {
  return (
    <div id="full-page-layout" className={className}>
      {children}
    </div>
  )
}

export default FullPage

import { useTranslation } from 'react-i18next'
import FullPage from '../../layouts/FullPage'
import './styles.scss'

function ThankYou () {
  const { t } = useTranslation()

  return (
    <FullPage className='d-flex flex-column align-items-center justify-content-center'>
      <div id="thank-you">
        <h1 className='text-white'>{t('thank_you')} 🙇‍♂️</h1>
      </div>
    </FullPage>
  )
}

export default ThankYou

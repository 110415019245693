import './App.scss'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { routes } from './routes'
import 'bootstrap'
import NotFound from './views/pages/NotFound'

function App () {
  return (
    <div className="container-fluid">
      <Router>
        <Routes>
          {routes.map(({ path, exact, element }) => (
            <Route key={path} path={path} element={element} exact={exact} />
          ))}

          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App

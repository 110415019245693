import FullPage from '../../layouts/FullPage'
import './styles.scss'

function Home () {
  return (
    <FullPage className='d-flex flex-column align-items-center justify-content-center'>
      <div id="home">
        <h1 className='text-white'>Tintosoft</h1>
      </div>
    </FullPage>
  )
}

export default Home

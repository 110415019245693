import Home from './views/pages/Home'
import NotFound from './views/pages/NotFound'
import Kuaa from './views/pages/Companies/Kuaa'
import ThankYou from './views/pages/ThankYou'

const routes = [
  { path: '/', element: <Home />, exact: true },
  { path: '/kuaa', element: <Kuaa />, exact: true },
  { path: '/thank-you', element: <ThankYou />, exact: true },
  { path: '*', element: <NotFound />, exact: true }
]

export { routes }

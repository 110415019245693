import { useEffect } from 'react'
import FullPage from '../../../layouts/FullPage'

function NodeBasic ({ company }) {
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'paypal-script'
    script.src = 'https://www.paypal.com/sdk/js?client-id=ASPBbedM7vQZPIqKXDlpiIhWbaB1sO-5TESnvvADMujoRTr0xaEzduGz3J3Py1ImjnKCi4hnhAZ7Uzzc&vault=true&intent=subscription'
    script.async = true
    script.onload = () => createPaypalButtons()

    document.body.appendChild(script)
  }, [])

  const createPaypalButtons = () => {
    window.paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'subscribe'
      },
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: 'P-9CL74194FT442161KMPZ5HTA'
        })
      },
      onApprove: function (data, actions) {
        alert(data.subscriptionID) // You can add optional success message for the subscriber here
      }
    }).render('#paypal-button-container-P-9CL74194FT442161KMPZ5HTA') // Renders the PayPal button
  }

  return (
    <FullPage className='d-flex flex-column align-items-center justify-content-center'>
      <h1 className='text-white mb-5 text-capitalize'>{ company }</h1>

      <div className="card" style={{ width: '600px', maxWidth: '100%' }}>
        <div className="card-body">
          <h2 className="card-title text-center">Subscripción a Pagos Automáticos</h2>
          <h3 className="text-center mb-3">Hosting Básico de NodeJS</h3>

          <ul className='mb-3'>
            <li>Servidor privado</li>
            <li>Comunicación encriptada con HTTPS</li>
            <li>Soporte personalizado</li>
            <li>Optimización de rendimiento y seguridad</li>
            <li>Copias de seguridad automáticas cada 7 días</li>
            <li>Entornos de prueba y producción</li>
          </ul>

          <h4 className="text-center mb-4">$ 20 USD / <small>mensuales</small></h4>

          <div id="paypal-button-container-P-9CL74194FT442161KMPZ5HTA"></div>
        </div>
      </div>
    </FullPage>
  )
}

export default NodeBasic
